import { useEffect, useState } from 'react';

import BlogList from './BlogList';
import { Link } from 'react-router-dom';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';


const HolidayPrograms = () => {
    const [blogs, setBlogs] = useState([])
  
  const blogArticles = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    setBlogs(data)
  }


  useEffect(() => {
    blogArticles();
  }, []);



  return (
    <>
    <section className="py-4 py-lg-5 bg-fancy-orange">
        <div className="container">
          <h2 className='text-white mb-4'>Holiday Programs</h2>
            {/* {
                blogs.length > 0
                ? (
                <div className="row">
                    {blogs.map((blog) => 
                      <div className='col-md-3 my-2 blog-list-page'>
                        <BlogList blog={blog} />
                      </div>
                    )}
                </div>
                )
                :
                <div>
                </div>
            } */}
            <div class="row">
              <div class="col-lg-4 my-2">
                <div class="program-card">
                  <div class="program-card-img">
                    <img src="./img/article-2.jpeg" />
                  </div>
                  <div class="program-content">
                    <h3 class="lc-2">Robotics and Automation Camp</h3>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-blue">1 month</span>
                      <span class="custom-badge badge-blue">9:00am - 2:00pm</span>
                      <span class="custom-badge badge-blue">Ages 12 - 14</span>
                    </span>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-grey">#robotics</span>  
                      <span class="custom-badge badge-grey">#arduino</span>  
                      <span class="custom-badge badge-grey">#automation</span>  
                    </span>
                    <p class="lc-3">Unleash your creativity with robotics! This camp is designed for kids aged 12-14 who want to delve into robotics and automation using Arduino. Participants will learn to build and program robots, explore sensors and actuators, and engage in exciting challenges that test their engineering skills. Get ready for a three-day journey into the fascinating world of robotics!</p>
                    <div class="d-flex align-items-center">
                      <a href="" class="btn btn-orange btn-sm">Enroll</a>
                      <span class="text-orange d-inline-block ms-4 fw-bold">KSh 10,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 my-2">
                <div class="program-card">
                  <div class="program-card-img">
                    <img src="./img/article-2.jpeg" />
                  </div>
                  <div class="program-content">
                    <h3 class="lc-2">Robotics and Automation Camp</h3>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-blue">1 month</span>
                      <span class="custom-badge badge-blue">9:00am - 2:00pm</span>
                      <span class="custom-badge badge-blue">Ages 12 - 14</span>
                    </span>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-grey">#robotics</span>  
                      <span class="custom-badge badge-grey">#arduino</span>  
                      <span class="custom-badge badge-grey">#automation</span>  
                    </span>
                    <p class="lc-3">Unleash your creativity with robotics! This camp is designed for kids aged 12-14 who want to delve into robotics and automation using Arduino. Participants will learn to build and program robots, explore sensors and actuators, and engage in exciting challenges that test their engineering skills. Get ready for a three-day journey into the fascinating world of robotics!</p>
                    <div class="d-flex align-items-center">
                      <a href="" class="btn btn-orange btn-sm">Enroll</a>
                      <span class="text-orange d-inline-block ms-4 fw-bold">KSh 10,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 my-2">
                <div class="program-card">
                  <div class="program-card-img">
                    <img src="./img/article-2.jpeg" />
                  </div>
                  <div class="program-content">
                    <h3 class="lc-2">Robotics and Automation Camp</h3>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-blue">1 month</span>
                      <span class="custom-badge badge-blue">9:00am - 2:00pm</span>
                      <span class="custom-badge badge-blue">Ages 12 - 14</span>
                    </span>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-grey">#robotics</span>  
                      <span class="custom-badge badge-grey">#arduino</span>  
                      <span class="custom-badge badge-grey">#automation</span>  
                    </span>
                    <p class="lc-3">Unleash your creativity with robotics! This camp is designed for kids aged 12-14 who want to delve into robotics and automation using Arduino. Participants will learn to build and program robots, explore sensors and actuators, and engage in exciting challenges that test their engineering skills. Get ready for a three-day journey into the fascinating world of robotics!</p>
                    <div class="d-flex align-items-center">
                      <a href="" class="btn btn-orange btn-sm">Enroll</a>
                      <span class="text-orange d-inline-block ms-4 fw-bold">KSh 10,000</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        {/* <div class="mt-4 text-center">
          <Link to="/blog" className="btn btn-outline-main">Go to Blog</Link>
        </div> */}
        </div>
    </section>
    </>
  );
}

export default HolidayPrograms;
