import { useEffect, useState } from 'react';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Testimonials = () => {
    // const API_URL = 'https://backend-api.techsyiq.com/testimonial/fetch';
    const API_URL = '';

    new Swiper('.testimonialSwipper', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
            delay: 2500,
            disableOnInteraction: true,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            999: {
            slidesPerView: 3,
            spaceBetween: 30,
            }
        }
    });
    

    const [testimonialList, setTestimonial] = useState([])
    
        const testimonials = async () => {
        const response = await fetch(API_URL);
        const data = await response.json();
        
        console.log(data)
        setTestimonial(data)
    }


    useEffect(() => {
        testimonials();
    }, []);

  return (
    <>
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-4">
                <h2 className="text-white">Testimonials</h2>
                <p className="text-light">Dui viverra fermentum pulvinar penatibus at. Aliquet ut ultricies sem urna ut faucibus. Turpis fusce velit adipiscing odio consequat. Sed consequat nam vivamus quam ut viverra sed.</p>
            </div>
            <div className="col-md-8">
                <div className="swiper testimonialSwipper">
                    <div className="swiper-wrapper">
                        {/* {testimonialList.map((testimonial) => 
                            <div className="swiper-slide">
                                <div className="testimonial-card">
                                    <img src={"https://res.cloudinary.com/dlkjbxanc/" + testimonial.image } className="prof-img" alt="" />
                                    <div className="testimonial-content">
                                        <a href={ testimonial.profile_link } target="_blank" className="linkedin-icon">
                                            <i className="bi bi-linkedin"></i>
                                        </a>
                                        <p className="mb-2">{ testimonial.description }</p>
                                        <img src={"https://res.cloudinary.com/dlkjbxanc/" + testimonial.logo } className="company-img" alt="" />
                                        <h5 className="mb-1">{ testimonial.name }</h5>
                                        <p className="small mb-0 text-muted lc-1">{ testimonial.title }</p>
                                    </div>
                                </div>
                            </div>
                        )} */}
                        <div className="swiper-slide">
                            <div className="testimonial-card">
                                <img src="./img/joseph.png" className="prof-img" alt="" />
                                <div className="testimonial-content">
                                    <a href="" target="_blank" className="linkedin-icon">
                                        <i className="bi bi-linkedin"></i>
                                    </a>
                                    <p className="mb-2">Best place to learn programming affordably and within a short time.</p>
                                    <img src="./img/saf.png" className="company-img" alt="" />
                                    <h5 className="mb-1">Joseph Mwai</h5>
                                    <p className="small mb-0 text-muted lc-1">Software Engineer, Safaricom.</p>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial-card">
                                <img src="./img/ivy.png" className="prof-img" alt="" />
                                <div className="testimonial-content">
                                    <a href="" target="_blank" className="linkedin-icon">
                                        <i className="bi bi-linkedin"></i>
                                    </a>
                                    <p className="mb-2">Best place to learn programming affordably and within a short time.</p>
                                    <img src="./img/saf.png" className="company-img" alt="" />
                                    <h5 className="mb-1">Christine Ewalo</h5>
                                    <p className="small mb-0 text-muted lc-1">Frontend Engineer, Apple Systems</p>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="testimonial-card">
                                <img src="./img/kevin.png" className="prof-img" alt="" />
                                <div className="testimonial-content">
                                    <a href="" target="_blank" className="linkedin-icon">
                                        <i className="bi bi-linkedin"></i>
                                    </a>
                                    <p className="mb-2">Best place to learn programming affordably and within a short time.</p>
                                    <img src="./img/saf.png" className="company-img" alt="" />
                                    <h5 className="mb-1">Joseph Mwai</h5>
                                    <p className="small mb-0 text-muted lc-1">Software Engineer, Safaricom.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="swiper-slide">
                            <div className="testimonial-card">
                                <img src="./img/joseph.png" className="prof-img" alt="" />
                                <div className="testimonial-content">
                                    <a href="" target="_blank" className="linkedin-icon">
                                        <i className="bi bi-linkedin"></i>
                                    </a>
                                    <p className="mb-2">Best place to learn programming affordably and within a short time.</p>
                                    <img src="./img/saf.png" className="company-img" alt="" />
                                    <h5 className="mb-1">Joseph Mwai</h5>
                                    <p className="small mb-0 text-muted lc-1">Software Engineer, Safaricom.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </div>
    </div>
    <span className="testimonial-vectors">
        <span className="v1"></span>
        <span className="v2"></span>
        <span className="v3"></span>
    </span>
    </>
  );
}

export default Testimonials;
