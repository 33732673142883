import Axios from "axios";
import Newsletters from "../Components/Newsletters";
import { useState } from "react";

const Contact = () => {

    const API_URL = 'https://backend-api.techsyiq.com/contact_us/message';

    const [data, setData] = useState({
        name: '',
        subject: '',
        email: '',
        message: ''
    })

    function submit(e){
        e.preventDefault();
        Axios.post(API_URL,{
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message
        }).then(res=>{
            document.getElementById('thankYou').classList.remove('d-none');
            document.getElementById('contactForm').reset()
        })
    }

    function dismissThankYou(){
        document.getElementById('thankYou').classList.add('d-none');
    }

    function handle(e){
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        console.log(newData)
    }

  return (
    <>
    <section id="thankYou" className="d-none form-submit-success" onClick={dismissThankYou}>
        <div className="thank-you-inner">
            <h3>Message Sent Successfully!</h3>
            <p>Your message was sent successfully! We will get in touch as soon as possible!</p>
            <button onClick={dismissThankYou} className="btn btn-main btn-sm">OK</button>
        </div>
    </section>
    <section class="py-4 py-lg-5">
        <div class="container">
            <div class="contact-us-card">
                <img src="./img/contact-favicon.svg" class="contact-us-page-favicon" />
                <div class="text-center mb-4">
                    <h1>Contact Us</h1>
                    <p>Open Monday - Saturday (7.00AM - 3.00PM)</p>
                </div>
                <div class="contact-card-inner">
                    <div class="col-lg-7 mx-lg-auto">
                        <div class="px-3 py-lg-3 add-row-between">
                            <div class="row mt-lg-5">
                                <div class="col-lg-6 order-2 order-lg-0 my-2">
                                    <div class="contact-page-contact-group">
                                        <small>General Inquiries</small>
                                        <span class="contact-info-flex">
                                            <span class="cif-img">
                                                <img src="./img/telephone.svg" alt="" />
                                            </span>
                                            <span class="cif-info">
                                                +254 723 456 789
                                            </span>
                                        </span>
                                    </div>
                                    <div class="contact-page-contact-group">
                                        <small>Admissions</small>
                                        <span class="contact-info-flex">
                                            <span class="cif-img">
                                                <img src="./img/telephone.svg" alt="" />
                                            </span>
                                            <span class="cif-info">
                                                +254 723 456 790
                                            </span>
                                        </span>
                                        <span class="contact-info-flex">
                                            <span class="cif-img">
                                                <img src="./img/whatsapp.svg" alt="" />
                                            </span>
                                            <span class="cif-info">
                                                +254 723 456 790
                                            </span>
                                        </span>
                                    </div>

                                    <div class="contact-page-contact-group">
                                        <small>Corporate</small>
                                        <span class="contact-info-flex">
                                            <span class="cif-img">
                                                <img src="./img/telephone.svg" alt="" />
                                            </span>
                                            <span class="cif-info">
                                                +254 723 456 791
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 order-1 order-lg-0 my-2">
                                    <div class="contact-page-contact-group">
                                        <small>Email</small>
                                        <span class="contact-info-flex">
                                            <span class="cif-img">
                                                <img src="./img/email.svg" alt="" />
                                            </span>
                                            <span class="cif-info">
                                                info@techsyiq.com
                                            </span>
                                        </span>
                                    </div>
                                    <div class="contact-page-contact-group">
                                        <small>Socials</small>
                                        <ul class="contact-page-social-icons">
                                            <li><a href="#"><img src="./img/instagram.svg"></img></a></li>
                                            <li><a href="#"><img src="./img/linkedin.svg"></img></a></li>
                                            <li><a href="#"><img src="./img/x.svg"></img></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-new-contact-inner">
                        <form onSubmit={(e)=> submit(e)} className="new-contact-form" id="contactForm">
                            {/* <h2>Send a Message</h2>
                            <p>Leave an enquiry here, and one of us will contact you to respond.</p> */}

                            <div className="row">
                                <div className="col-md-12 mb-1">
                                    <label for="name" className="small">Your Name</label>
                                    <input type="text" onChange={(e)=>handle(e)} className="form-control" id="name" />
                                </div>
                                <div className="col-md-12 mb-1">
                                    <label for="email" className="small">Email Address</label>
                                    <input type="email" onChange={(e)=>handle(e)} className="form-control" id="email" />
                                </div>
                                <div className="col-md-12 my-1">
                                    <label for="subject" className="small">Subject</label>
                                    <input type="text" onChange={(e)=>handle(e)} className="form-control" id="subject" />
                                </div>
                                <div className="col-md-12 my-1">
                                    <label for="message" className="small">Your Message</label>
                                    <textarea className="form-control" id="message" onChange={(e)=>handle(e)} rows="4"></textarea>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <button type="submit" className="btn btn-main">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
            </div>
        </div>
    </section>
    <Newsletters />

    </>
  );
}

export default Contact;
