const TermsAndConditions = () => {
  return (
    <>
      <section class="py-4 py-lg-5">
        <div class="container">
            <h1>Terms and Conditions</h1>
            <p>Terms and Conditions</p>
        </div>
      </section>

    </>
  );
}

export default TermsAndConditions;
