import { useEffect, useState } from "react";

const Stats = () => {

    const API_URL = 'https://backend-api.techsyiq.com/marketing/fetch_analysis_details';

    const [statsList, setStats] = useState([])
  
    const stats = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setStats(data)
  }

  useEffect(() => {
    stats();
  }, []);

  return (
    <>
    {/* {statsList.map((stats) => 
      <section class="py-4 py-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-md-4 my-3">
                    <div class="stat-card">
                        <h3>{ stats.employed_alumin }+</h3>
                        <p class="small text-muted">Employed Alumni</p>
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="stat-card">
                        <h3>{ stats.graduates }+</h3>
                        <p class="small text-muted">Cohorts Graduated</p>
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="stat-card">
                        <h3>{ stats.commitment_percentage }%</h3>
                        <p class="small text-muted">Our Commitment</p>
                    </div>
                </div>
            </div>
        </div>
      </section>
      )} */}
      <section class="py-4 py-lg-5">
        <div class="container">
            <div class="stat-white-card">
              <div class="sm-pink-bg">
                <div class="row">
                    <div class="col-4 my-3">
                        <div class="stat-card">
                            <h3>200+</h3>
                            <p class="small text-muted">Employed Alumni</p>
                        </div>
                    </div>
                    <div class="col-4 my-3">
                        <div class="stat-card">
                            <h3>3</h3>
                            <p class="small text-muted">Cohorts Graduated</p>
                        </div>
                    </div>
                    <div class="col-4 my-3">
                        <div class="stat-card">
                            <h3>100%</h3>
                            <p class="small text-muted">Our Commitment</p>
                        </div>
                    </div>
                </div>
              </div>
              <div class="col-lg-9 mx-lg-auto mt-3 text-center">
                <h2>Enroll in our coding school <span class="text-blue">now</span> and kickstart your
                <span class="text-blue">successful</span> technology career</h2>
                <p>We admit a new cohort every 2 months, but we are always open for  enquiries.</p>
                <a href="" class="btn btn-main">Apply Now</a>
              </div> 

            </div>
        </div>
      </section>
    </>
  );
}

export default Stats;
