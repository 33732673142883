import { useEffect, useState } from 'react';

const Navbar = () => {
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light sticky-top shadow-sm bg-white" id="navBar">
        <div className="container">
          <a href="/" className="navbar-brand">
          <svg width="139" height="32" viewBox="0 0 139 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2541_391)">
              <path d="M17.1222 11.3688V8.15332C17.1222 8.01285 17.008 7.90016 16.8691 7.90016H0.25316C0.114231 7.89862 0 8.01285 0 8.15332V17.5218C0 21.6557 3.20772 25.1814 7.33854 25.3373C11.6808 25.5009 15.2668 22.0169 15.2668 17.7086V14.0007C15.2668 13.8602 15.1525 13.7475 15.0136 13.7475H11.7982C11.6577 13.7475 11.545 13.8618 11.545 14.0007V17.5943C11.545 19.6289 10.0369 21.4118 8.01158 21.6017C5.68683 21.8209 3.7233 19.9901 3.7233 17.7086V11.8751C3.7233 11.7346 3.83753 11.6219 3.97646 11.6219H16.8675C17.008 11.6219 17.1207 11.5077 17.1207 11.3688H17.1222Z" fill="#333333"/>
              <path d="M1.87247 4.67854C2.90148 4.67854 3.73566 3.84436 3.73566 2.81534C3.73566 1.78633 2.90148 0.952148 1.87247 0.952148C0.843457 0.952148 0.00927734 1.78633 0.00927734 2.81534C0.00927734 3.84436 0.843457 4.67854 1.87247 4.67854Z" fill="#C83898"/>
              <path d="M7.6503 4.67854C8.67932 4.67854 9.5135 3.84436 9.5135 2.81534C9.5135 1.78633 8.67932 0.952148 7.6503 0.952148C6.62129 0.952148 5.78711 1.78633 5.78711 2.81534C5.78711 3.84436 6.62129 4.67854 7.6503 4.67854Z" fill="#F79F28"/>
              <path d="M13.4281 4.67854C14.4571 4.67854 15.2913 3.84436 15.2913 2.81534C15.2913 1.78633 14.4571 0.952148 13.4281 0.952148C12.3991 0.952148 11.5649 1.78633 11.5649 2.81534C11.5649 3.84436 12.3991 4.67854 13.4281 4.67854Z" fill="#3778B8"/>
              <path d="M38.9463 23.7723C38.9803 23.9421 39.0143 23.9421 39.0143 24.0455C39.0143 24.6939 36.5228 25.3422 34.2011 25.3422C29.7971 25.3422 29.7971 21.8937 29.7971 18.7539V11.0726H27.3736C26.9645 11.0726 26.895 10.9708 26.895 10.5956V8.37742C26.895 8.00231 26.9629 7.89888 27.3736 7.89888H29.7971V3.59671C29.7971 3.15368 29.865 3.05026 30.3436 2.98234L33.3475 2.57327C33.4494 2.57327 33.5868 2.53931 33.7921 2.53931C34.0993 2.53931 34.1332 2.70911 34.1332 3.11972V7.89888H38.0248C38.332 7.89888 38.6052 7.89888 38.6052 8.27399C38.6052 8.34191 38.6052 8.41138 38.5712 8.54722L38.1267 10.5617C38.0248 11.0402 37.9214 11.0742 37.5123 11.0742H34.1332V19.3358C34.1332 20.4287 34.1332 21.4182 35.2941 21.4182C36.6602 21.4182 37.5123 20.7699 37.9908 20.7699C38.1267 20.7699 38.2301 20.8378 38.2641 20.9752L38.9463 23.7738V23.7723Z" fill="#333333"/>
              <path d="M53.2886 22.9523C53.3565 23.0558 53.3904 23.1916 53.3904 23.3275C53.3904 24.2151 49.9759 25.3419 47.2791 25.3419C42.7732 25.3419 39.6318 22.781 39.6318 16.4999C39.6318 11.0724 42.5339 7.31665 47.1078 7.31665C52.9119 7.31665 53.9354 12.5064 53.9354 16.6357C53.9354 17.284 53.7995 17.4893 53.1496 17.4893H43.9664C43.9664 19.4004 44.5129 21.756 47.7221 21.756C50.1796 21.756 51.5797 20.6291 52.0583 20.6291C52.2296 20.6291 52.3655 20.765 52.4334 20.9024L53.287 22.9508L53.2886 22.9523ZM49.3291 14.3156C49.3291 12.9155 49.2272 10.492 46.8716 10.492C44.2088 10.492 44.0729 13.3601 43.9695 14.3156H49.3291Z" fill="#333333"/>
              <path d="M67.7466 23.1916C67.7806 23.2935 67.8485 23.4309 67.8485 23.5328C67.8485 24.3524 65.0838 25.3419 63.0354 25.3419C58.2902 25.3419 55.4561 22.4399 55.4561 16.6712C55.4561 10.9026 58.3581 7.31665 63.4105 7.31665C65.2536 7.31665 68.2576 8.34009 68.2576 9.39904C68.2576 9.50092 68.1897 9.60435 68.1557 9.70623L66.893 11.9245C66.8251 11.9924 66.7572 12.0943 66.6537 12.0943C66.3126 12.0943 65.1857 10.7281 63.4105 10.7281C61.0889 10.7281 60.0654 12.5033 60.0654 16.4289C60.0654 20.3544 60.953 21.9258 63.5819 21.9258C65.3231 21.9258 66.0054 20.765 66.6198 20.765C66.7217 20.765 66.7911 20.8329 66.859 20.9363L67.7466 23.1901V23.1916Z" fill="#333333"/>
              <path d="M83.2603 24.2848C83.2603 24.6599 83.1924 24.7633 82.7818 24.7633H79.4027C78.9936 24.7633 78.9242 24.6615 78.9242 24.2848V13.771C78.9242 12.4743 78.7528 10.9028 77.0131 10.9028C75.579 10.9028 74.5556 11.9263 74.111 12.3369V24.2848C74.111 24.6599 74.0431 24.7633 73.6325 24.7633H70.2534C69.8444 24.7633 69.7749 24.6615 69.7749 24.2848V1.34452C69.7749 0.832029 70.0142 0.832029 70.4232 0.730148L73.2914 0.149733C73.3932 0.149733 73.7004 0.0478516 73.7699 0.0478516C74.0431 0.0478516 74.111 0.183693 74.111 0.764108V9.26348C75.0326 8.51326 76.3987 7.31693 78.8223 7.31693C82.4406 7.31693 83.2603 9.74047 83.2603 12.7105V24.2833V24.2848Z" fill="#333333"/>
              <path d="M95.4364 11.3117C95.3685 11.4815 95.1972 11.6189 95.1632 11.6189C95.0613 11.6189 93.388 10.7313 91.2037 10.7313C89.9071 10.7313 88.95 11.2098 88.95 12.3691C88.95 14.2122 92.0219 14.0764 94.447 15.6803C95.7791 16.5679 96.7347 17.8645 96.7347 19.845C96.7347 22.9864 94.5164 25.342 90.8966 25.342C88.3711 25.342 84.478 24.0453 84.478 23.1238C84.478 23.0203 84.6139 22.8166 84.6494 22.6792L85.4011 20.7326C85.4691 20.5628 85.537 20.4255 85.6744 20.4255C85.8117 20.4255 88.439 21.9274 90.5554 21.9274C91.6823 21.9274 92.6378 21.4489 92.6378 20.2202C92.6378 18.2396 89.2927 18.2396 87.0389 16.7377C86.014 16.0554 85.0245 14.8606 85.0245 12.8801C85.0245 9.77423 87.3801 7.31519 91.0664 7.31519C93.1487 7.31519 96.392 8.20279 96.392 8.98851C96.392 9.09193 96.2901 9.2957 96.2206 9.43308L95.4349 11.3102L95.4364 11.3117Z" fill="#333333"/>
              <path d="M109.082 8.30781C109.185 7.9327 109.355 7.59155 109.662 7.59155C109.799 7.59155 110.174 7.62551 110.276 7.62551L112.666 7.9327C113.178 8.00062 113.416 8.03458 113.416 8.27385C113.416 8.41123 113.314 8.615 113.246 8.88822L109.901 21.2128C109.287 23.431 108.365 26.9135 107.613 28.7906C106.622 31.282 104.508 31.9998 102.595 31.9998C100.682 31.9998 97.9517 31.0783 97.9517 30.43C97.9517 30.2941 98.0197 30.1567 98.0876 30.0209L99.1126 27.8026C99.1805 27.6313 99.3179 27.5634 99.4197 27.5634C99.7609 27.5634 100.82 28.417 102.322 28.417C104.097 28.417 104.61 27.3581 105.053 25.8916L105.394 24.7647H103.96C103.517 24.7647 103.447 24.6289 103.345 24.3217L98.1215 8.89131C98.0876 8.82339 97.9857 8.55016 97.9502 8.3109C97.9502 8.03767 98.1895 8.00371 98.702 7.93579L101.536 7.6286C101.638 7.6286 102.015 7.59464 102.083 7.59464C102.356 7.59464 102.458 7.93579 102.561 8.27694L104.779 16.5386C105.19 18.0745 105.872 21.2838 106.078 22.4446H106.145C106.315 21.1124 107.135 17.153 107.272 16.5386L109.082 8.3109V8.30781Z" fill="#333333"/>
              <path d="M122.074 8.37722V24.2846C122.074 24.6597 122.006 24.7616 121.597 24.7616H118.218C117.809 24.7616 117.741 24.6597 117.741 24.2846V11.074H115.109C114.7 11.074 114.632 10.9721 114.632 10.597V8.37876C114.632 8.00365 114.7 7.89868 115.109 7.89868H121.597C122.006 7.89868 122.074 8.00365 122.074 8.37876V8.37722Z" fill="#333333"/>
              <path d="M137.94 30.7031C137.94 31.1462 137.7 31.2496 137.291 31.3175L134.423 31.8979C134.321 31.9319 133.98 31.9998 133.843 31.9998C133.673 31.9998 133.603 31.864 133.603 31.2836V24.456C132.887 24.8311 132.034 25.3436 130.189 25.3436C125.751 25.3436 123.908 21.9985 123.908 16.0245C123.908 10.0506 126.024 7.31982 129.814 7.31982C132.443 7.31982 133.535 8.44669 134.218 9.06107C134.252 8.75388 134.97 7.31982 135.481 7.31982C135.652 7.31982 135.822 7.38775 135.993 7.42171L138.518 8.27535C138.69 8.34327 138.927 8.48065 138.927 8.68442C138.927 9.09503 137.938 9.87921 137.938 14.044V30.7031H137.94ZM133.603 12.0295C133.126 11.5865 132.307 10.9027 130.873 10.9027C128.892 10.9027 128.244 12.8492 128.244 16.1943C128.244 20.2217 128.858 21.929 131.112 21.929C132.444 21.929 132.989 21.6218 133.603 21.2807V12.0295Z" fill="#333333"/>
              <path d="M122.69 2.80019C122.69 4.37009 121.429 5.63126 119.893 5.63126C118.357 5.63126 117.059 4.37009 117.059 2.80019C117.059 1.23029 118.32 0 119.893 0C121.466 0 122.69 1.22721 122.69 2.80019Z" fill="#3778B8"/>
              </g>
              <defs>
              <clipPath id="clip0_2541_391">
              <rect width="138.929" height="32" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="bar-1"></span>
            <span className="bar-2"></span>
            <span className="bar-3"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <button className="close-mobile-menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            &times;
          </button>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a className="nav-link custom-dropdown" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Courses
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a href="/techie-mod" className='dropdown-item'>Techie Mod <i class="bi bi-arrow-right"></i></a></li>
                    <li><a href="/front-end-dev" className='dropdown-item'>Front-end Dev  <i class="bi bi-arrow-right"></i></a></li>
                    <li><a href="/back-end-dev" className='dropdown-item'>Back-end Dev  <i class="bi bi-arrow-right"></i></a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="/about" className='nav-link'>About Us</a>
              </li>
              <li className="nav-item">
                <a href="/code-buds" className='nav-link'>Codebuds <span className='nav-badge'>KIDS</span> </a>
              </li>
              <li className="nav-item">
                <a href="/blog" className='nav-link'>Blog</a>
              </li>
              <li className="nav-item">
                <a href="/contact-us" className='nav-link'>Contact Us</a>
              </li>
            </ul>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link btn btn-white" href="https://lms.techsyiq.com/login/">Log In</a>
              </li>
            </ul>
          </div>
        </div>
    </nav>
  );
}

export default Navbar;
