import { useEffect, useState } from 'react';
import OtherArticles from '../Components/OtherArticles';
import ArticleFAQs from '../Components/ArticleFAQs';
import CallToAction from '../Components/CallToAction';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import Author from './Author';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blog';


const ArticleOld = () => {
  const [article, setArticle] = useState(null)

  const params = useParams();
  const blogArticle  = () => { fetch(`${API_URL}/${params.id}`) 
    .then(response => {
      return response.json() 
    }) 
    .then(data => {
      setArticle(data)
      console.log(data)
    })}


  useEffect(() => {
    setTimeout(() => {
      blogArticle();
    }, 50);
  }, []);

  return (
    <>
    {article ? (
      <section className="py-4 py-lg-5">
        <div className="container">
            <p className="text-muted small lc-1 fw-400">
              <Link to="/" className='text-blue'>Techsyiq</Link> 
              <span className="mx-2"><i className="bi bi-chevron-right"></i></span>
              <Link to="/blog" className='text-blue'>Blog</Link>
              <span className="mx-2"><i className="bi bi-chevron-right"></i></span>
              { article.title }
            </p>
            <p className="small text-muted mb-1 fw-400">
              <a href="" className="text-blue">{ article.category_name }</a>
            </p>
            <h1>
                { article.title }
            </h1>
            <p className="small text-muted mb-1 fw-400">
              <Moment format="YYYY/MM/DD">{ article.date_published }</Moment>
            </p>

            <img src={"https://res.cloudinary.com/dlkjbxanc/" + article.image } className='single-article-img' width="100%" alt={ article.title } />


            <div className='article-article'>{ article.description }</div>
            
            <Author id={article.author} />
        </div>
    </section>
    ) : null}

    <OtherArticles />

    <ArticleFAQs />

    <CallToAction />
    </>
  );
}

export default ArticleOld;
