import { useEffect, useState } from 'react';

import CallToAction from '../Components/CallToAction';




const About = () => {
    const API_URL = 'https://backend-api.techsyiq.com/team/fetch';

  const [teamList, setTeam] = useState([])
  
    const teamMembers = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setTeam(data)
  }


  useEffect(() => {
    teamMembers();
  }, []);

  return (
    <>
      <section className="about-hero-section py-4 py-lg-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <h1>We <span class="text-blue">empower Africa</span></h1>
                        <p>
                            Sed ut sit nec ultrices. Diam enim, augue auctor quisque senectus enim, nibh ipsum. Feugiat tempor nisi, ut dolor pellentesque egestas. Tristique dictumst odio justo, volutpat. Pharetra, mi, venenatis ut id volutpat habitant. 
                        </p>
                    </div>
                    <div className="col-lg-6 ps-lg-5">
                        <img src="./img/about-img.png" width="100%" alt="" />
                        <span className="vector-1"></span>
                        <span className="vector-2"></span>
                        <span className="vector-3"></span>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-4 py-lg-5 bg-white">
            <div className="container">
                <h2>Meet our team</h2>
                <div class="col-lg-6">
                    <p className="mb-5">To be the coding school our students expect us to be, it takes an eclectic, vibrant group of passionate individuals. Meet the people leading the way at Techsyiq.</p>
                </div>
                
                <div class="team-section">
                    
                    {teamList.map((team) => 
                    <div className="row team-card-row align-items-center my-3">
                        <div className="col-md-3 team-row-img my-3 pe-lg-4">
                            <div className="team-card">
                                <div className="team-img">
                                    <img src={"https://res.cloudinary.com/dlkjbxanc/" + team.image } alt="" />
                                </div>
                                <div className="team-footer">
                                    <div className="row">
                                        <div className="col-10">
                                            <p className="mb-0 fw-500">{ team.name }</p>
                                            <p className="small mb-0 text-muted">{ team.title }</p>
                                        </div>
                                        <div className="col-2 text-end">
                                            <a href={ team.social_media_link } className="text-blue">
                                                <i className="bi bi-twitter"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 team-row-description my-3">
                            <h3 className="text-blue">About { team.name }</h3>
                            <p>{ team.description }</p>
                        </div>
                    </div>
                    )}
                </div>
                <div class="new-team-section">
                    <div class="row">
                        <div class="col-lg-6 my-3">
                            <div class="new-team-card">
                                <div class="team-image-sec">
                                    <img src="./img/fidel.png" />
                                    <div class="team-card-info">
                                        <h3>Fidel Munywoki</h3>
                                        <small>CEO</small>
                                        <a href="#"><i class="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div class="team-about-sect">
                                    <p>Quis nunc blandit est pharetra aliquam venenatis. Lectus cursus nibh sed eget non lacus euismod. Sit risus, aliquet adipiscing viverra. Massa auctor proin ac tempor maecenas. Congue libero, dolor volutpat imperdiet. Mattis mauris erat pulvinar massa a diam tempus cras lacus. Enim duis tempor, et tellus quis egestas tristique pellentesque elementum.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-3">
                            <div class="new-team-card">
                                <div class="team-image-sec">
                                    <img src="./img/langat.png" />
                                    <div class="team-card-info">
                                        <h3>Lang’at Kipkirui</h3>
                                        <small>Director, Technology</small>
                                        <a href="#"><i class="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div class="team-about-sect">
                                    <p>Quis nunc blandit est pharetra aliquam venenatis. Lectus cursus nibh sed eget non lacus euismod. Sit risus, aliquet adipiscing viverra. Massa auctor proin ac tempor maecenas. Congue libero, dolor volutpat imperdiet. Mattis mauris erat pulvinar massa a diam tempus cras lacus. Enim duis tempor, et tellus quis egestas tristique pellentesque elementum.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-3">
                            <div class="new-team-card">
                                <div class="team-image-sec">
                                    <img src="./img/peter.png" />
                                    <div class="team-card-info">
                                        <h3>Peter Kennedy</h3>
                                        <small>Director, UX & Branding</small>
                                        <a href="#"><i class="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div class="team-about-sect">
                                    <p>Quis nunc blandit est pharetra aliquam venenatis. Lectus cursus nibh sed eget non lacus euismod. Sit risus, aliquet adipiscing viverra. Massa auctor proin ac tempor maecenas. Congue libero, dolor volutpat imperdiet. Mattis mauris erat pulvinar massa a diam tempus cras lacus. Enim duis tempor, et tellus quis egestas tristique pellentesque elementum.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-3">
                            <div class="new-team-card">
                                <div class="team-image-sec">
                                    <img src="./img/mohamed.png" />
                                    <div class="team-card-info">
                                        <h3>Jamal Mohamed</h3>
                                        <small>Director, Educational Content</small>
                                        <a href="#"><i class="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                                <div class="team-about-sect">
                                    <p>Quis nunc blandit est pharetra aliquam venenatis. Lectus cursus nibh sed eget non lacus euismod. Sit risus, aliquet adipiscing viverra. Massa auctor proin ac tempor maecenas. Congue libero, dolor volutpat imperdiet. Mattis mauris erat pulvinar massa a diam tempus cras lacus. Enim duis tempor, et tellus quis egestas tristique pellentesque elementum.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-4 py-lg-5">
            <div class="container">
                <div class="quote-box">
                    <p>Education should teach you how to <span class="text-blue">think</span>, how to <span class="text-blue">learn on your own</span>, how to <span class="text-blue">fail intelligently</span>, and how to <span class="text-blue">solve problems</span>.</p>
                    <h6>Naval Ravikant</h6>
                    <small class="text-muted">Entrepreneur and investor</small>
                </div>
            </div>
        </section>

        <CallToAction />

    </>
  );
}

export default About;
