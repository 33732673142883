import Testimonials from '../Components/Testimonials';
import CourseTutors from '../Components/CourseTutors';
import LearningProcess from '../Components/LearningProcess';
import CallToAction from '../Components/CallToAction';

const FrontEndDev = () => {
  return (
    <main class="theme-orange">
        <section className="course-hero-section py-4 py-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-7 my-3">
                        <p className="text-muted">Beginner Level | Remote</p>
                        <h1 className="text-theme">Front-end Dev</h1>

                        <ul className="course-ul my-4">
                            <li>Live class</li>
                            <li>Up to 15 students per class</li>
                            <li>8 weeks</li>
                            <li>Course materials and classes are in English</li>
                        </ul>

                        <a href="/apply-now" className="btn btn-theme">Apply Now</a>
                    </div>
                    <div className="col-md-6 col-lg-5 my-3 ps-lg-5">
                        <img src="./img/course-hero.svg" width="100%" alt="" />
                        <span className="vector-1"></span>
                        <span className="vector-2"></span>
                        <span className="vector-3"></span>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-dark text-white py-4 py-lg-5">
            <div className="container">
                <p className="mb-0">Take your first steps in front-end development. Our course has a project-based approach, so you will create your own project from scratch during the HTML/CSS, JavaScript, and Vue.js framework modules. Gain knowledge to master the profession independently and learn to speak the tech community's and developers' language.</p>
            </div>
        </section>
        <section className="py-4 py-lg-5">
            <div className="container">
                <h2 className="mb-4">Who is this course for?</h2>
                <div className="row">
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/switch-careers.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You are starting or switching a career</h3>
                            <p>You'd like to enter the tech world, and front-end development suits your needs the best. Or you'd like to switch to development from another tech profession to understand where to start and get some relevant knowledge.</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/dead-end.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You've run into a dead-end with self-education</h3>
                            <p>You may have tried web layout, and you're following trends in development. But you'd like to gather your knowledge, advance your skills, and get feedback from industry experts.</p>
                        </div>
                    </div>
                    <div className="col-md-4 my-3">
                        <div className="why-us-card">
                        <img src="./img/pm.svg" width="100%" alt="" />
                            <h3 className="text-dark fw-400 font-noto mb-4">You're a PM, a BA, or a product owner</h3>
                            <p>You're already working in IT and aiming to understand developers better. Furthermore, you want to speak your colleagues' language, gain technical knowledge to back up your decisions, and comprehend the code.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="pb-4 pb-lg-5">
            <div className="container">
                <h2 className="mb-4">What job descriptions will fit you?</h2>

                <div className="site-card bg-theme job-description">
                    <div className="row">
                        <div className="col-2 col-lg-1">
                            <div className="job-profile">
                                <img src="./img/apple.png" alt="" />
                            </div>
                        </div>
                        <div className="col-10 col-lg-11">
                            <small>Role:</small>
                            <h3>Junior front-end developer</h3>
                        </div>
                    </div>
                    <hr className="bg-white" />
                    <h3 className="font-noto">Skills</h3>
                    <ul>
                        <div className="row gx-lg-5">
                            <div className="col-lg-4">
                                <li>Knowledge of HTML and skills of semantic markup</li>
                                <li>Experience in creating CSS animations, transformations and using other styles</li>
                                <li>Skills in using pre- and post-processors </li>
                                <li>Skills of working with basic JavaScript tools </li>
                                <li>Experience working with web servers</li>
                            </div>
                            <div className="col-lg-4">
                                <li>Experience with Git and GitHub </li>
                                <li>Understanding of BEM methodology </li>
                                <li>Experience with Figma </li>
                                <li>Ability to work with console and DevTools, check the code for errors, perform debugging </li>
                                <li>Knowledge of the logic of client-server architecture when building applications</li>
                            </div>
                            <div className="col-lg-4">
                                <li>Skills in applying CSS-styles and visual effects to the layout </li>
                                <li>Experience creating responsive websites </li>
                                <li>Experience using JavaScript (primitives, objects, arrays, functions)</li>
                                <li>Experience with manipulating the DOM tree </li>
                                <li>Experience in creating web applications with Vue.js</li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </section>
        <section className="py-4 theme-accordion py-lg-5">
            <div className="container">
                <h2 className="mb-4 text-center">Course Outline</h2>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span>
                            <span className="site-accordion-title">HTML & CSS</span>
                            <span className="text-theme small accordion-small">60 hours <span className="mx-2">&middot;</span> 24 topics</span>
                        </span>
                    </button>
                    <div id="collapseOne" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Aliquam saepe quisquam nihil vel debitis nesciunt rem nisi perferendis, excepturi nam voluptatem at eos odio perspiciatis. Facere architecto ipsam expedita tempore, consectetur et aut error ipsum dicta sit tempora rem at blanditiis, fuga odio voluptate.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span>
                            <span className="site-accordion-title">JavaScript</span>
                            <span className="text-theme small accordion-small">40 hours <span className="mx-2">&middot;</span> 18 topics</span>
                        </span>
                    </button>
                    <div id="collapseThree" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Maiores rem impedit voluptates veniam quasi eligendi enim minima nulla temporibus molestiae vero in, unde perspiciatis corrupti vitae esse officiis dignissimos, quod a placeat.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span>
                            <span className="site-accordion-title">JavaScript</span>
                            <span className="text-theme small accordion-small">40 hours <span className="mx-2">&middot;</span> 18 topics</span>
                        </span>
                    </button>
                    <div id="collapseFour" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Maiores rem impedit voluptates veniam quasi eligendi enim minima nulla temporibus molestiae vero in, unde perspiciatis corrupti vitae esse officiis dignissimos, quod a placeat.
                    </div>
                    </div>
                </div>
            </div>
        </section>

        <CourseTutors />
        <LearningProcess />
        {/* <section className="pb-4 pb-lg-5">
            <div className="container">
            <h2 className="fancy-heading">Why Us?</h2>
            <div className="row mt-4 gx-lg-5">
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/FlippedClassrooms.svg" alt="Flipped classrooms" />
                        <h3>Flipped classrooms</h3>
                        <p>Study theory at home and focus on practice in live classNamees, where you’ll be guided by one of our expert tutors.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/LiveClasses.svg" alt="Live classes" />
                        <h3>Live classes</h3>
                        <p>Join our Zoom classes led by industry experts — up to 15 students per className, during stand-ups.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Support.svg" alt="Support" />
                        <h3>Support</h3>
                        <p>Get the help you need to break into your new career. We provide our students with meaningful support and career guidance.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/SoftSkills.svg" alt="Soft Skills" />
                        <h3>Soft Skills</h3>
                        <p>Go beyond the technical stuff. Our approach emulates a real tech working environment, where our students work in groups, guided by a team lead.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Employability.svg" alt="Employability" />
                        <h3>Employability</h3>
                        <p>Landing a job in tech can be tough — but we’ll provide you with the tools and knowledge necessary to nail your first interview.</p>
                    </div>
                </div>
                <div className="col-md-4 my-2">
                    <div className="why-us-card">
                        <img src="./img/Community.svg" alt="Community" />
                        <h3>Community</h3>
                        <p>Get access to the Techsyiq Community that comprises our alumni, a vibrant, innovative development company, and a growing network of partners.</p>
                    </div>
                </div>
            </div>
            </div>
        </section> */}
        <section class="bg-white tuition-fees pb-4 pb-lg-5">
            <div class="container">
                <h2 class="text-pink fs-1">Tuition Fees</h2>
                <p>Switching careers can be challenging, and we understand. We’re here to provide you with the flexibility you need.</p>

                <div class="row mt-4">
                    <div class="my-2 col-lg-3">
                        <div class="tuition-card popular-tc">
                            <div class="w-100">
                                <p class="tc-desc">Full Tuition</p>
                                <h3 class="tc-price">KSh50,000</h3>
                                <p class="tc-payment">One-time full payment</p>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 col-lg-3">
                        <div class="tuition-card">
                            <div class="w-100">
                                <p class="tc-desc">Installments plan</p>
                                <h3 class="tc-price text-pink">KSh18,000 <span class="small-small">pm</span></h3>
                                <p class="tc-payment">3 monthly payments</p>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 col-lg-3">
                        <div class="tuition-card">
                            <div class="w-100">
                                <p class="tc-desc">Talk to us</p>
                                <a class="btn btn-gradient btn-lg" href="#">Book a call</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-4 theme-accordion py-lg-5">
            <div className="container">
                <h2 className="mb-4 text-center">Frequently Asked Questions (FAQs)</h2>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneFAQs" aria-expanded="true" aria-controls="collapseOneFAQs">
                        <span>
                            <span className="site-accordion-title">You dedicate so much time to HTML and CSS. Why?</span>
                        </span>
                    </button>
                    <div id="collapseOneFAQs" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Aliquam saepe quisquam nihil vel debitis nesciunt rem nisi perferendis, excepturi nam voluptatem at eos odio perspiciatis. Facere architecto ipsam expedita tempore, consectetur et aut error ipsum dicta sit tempora rem at blanditiis, fuga odio voluptate.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoFAQs" aria-expanded="false" aria-controls="collapseTwoFAQs">
                        <span>
                            <span className="site-accordion-title">Are your courses competitive?</span>
                        </span>
                    </button>
                    <div id="collapseTwoFAQs" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Maiores rem impedit voluptates veniam quasi eligendi enim minima nulla temporibus molestiae vero in, unde perspiciatis corrupti vitae esse officiis dignissimos, quod a placeat.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeFAQs" aria-expanded="false" aria-controls="collapseThreeFAQs">
                        <span>
                            <span className="site-accordion-title">Do you guarantee employment after the course?</span>
                        </span>
                    </button>
                    <div id="collapseThreeFAQs" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Maiores rem impedit voluptates veniam quasi eligendi enim minima nulla temporibus molestiae vero in, unde perspiciatis corrupti vitae esse officiis dignissimos, quod a placeat.
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourFAQs" aria-expanded="false" aria-controls="collapseFourFAQs">
                        <span>
                            <span className="site-accordion-title">Why don’t you teach Angular?</span>
                        </span>
                    </button>
                    <div id="collapseFourFAQs" className="accordion-collapse collapse">
                    <div className="accordion-body">
                        Maiores rem impedit voluptates veniam quasi eligendi enim minima nulla temporibus molestiae vero in, unde perspiciatis corrupti vitae esse officiis dignissimos, quod a placeat.
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <CallToAction />
    </main>
  );
}

export default FrontEndDev;
