import { useEffect, useState } from 'react';

import Newsletters from "../Components/Newsletters";
import Testimonials from "../Components/Testimonials";

import HomeCourseList from '../Components/HomeCourseList';
import Stats from '../Components/Stats';
const API_URL = 'https://backend-api.techsyiq.com/course/fetch_course';

const Home = () => {
    const [courseList, setCourses] = useState([])
  
    const courses = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setCourses(data)
  }


  useEffect(() => {
    courses();
  }, []);



  return (
    <>

      <section class="hero-section">
        <div class="py-4 py-lg-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1>Web Development <br/>
                            <span class="text-blue">Bootcamp</span>
                        </h1>
                        <p class="home-desc">From African's leading technology and innovation hub, Kenya, we are planting the seeds for the next breed of techies, and with it a robust, thriving community of learning, sharing, having fun, and nurturing software developers. We welcome everyone!</p>

                        <p class="hero-styled-p">Applications open until Aug 23rd, 2024</p>

                        <a href="/apply-now" className="btn btn-pink">Apply Now</a>
                    </div>
                    <div class="col-lg-6">
                        <span class="vector-1"></span>
                        <span class="vector-2"></span>
                        <span class="vector-3"></span>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section class="py-4 py-lg-5">
        <div class="container">
            <div class="dark-container">
                <h2>Our graduates work at</h2>
                <div class="row mt-4">
                    <div class="col-4 col-lg-2 my-2">
                        <div class="dark-c-img">
                            <img src="./img/safaricom.png" alt="Safaricom" />
                        </div>
                    </div>
                    <div class="col-4 col-lg-2 my-2">
                        <div class="dark-c-img">
                            <img src="./img/citizen.jpeg" alt="Citizen" />
                        </div>
                    </div>
                    <div class="col-4 col-lg-2 my-2">
                        <div class="dark-c-img">
                            <img src="./img/kcb.png" alt="KCB" />
                        </div>
                    </div>
                    <div class="col-4 col-lg-2 my-2">
                        <div class="dark-c-img">
                            <img src="./img/cak.png" alt="CAK" />
                        </div>
                    </div>
                    <div class="col-4 col-lg-2 my-2">
                        <div class="dark-c-img">
                            <img src="./img/ami-truck.png" alt="AMI Truck" />
                        </div>
                    </div>
                    <div class="col-4 col-lg-2 my-2">
                        <div class="dark-c-img">
                            <img src="./img/gok.png" alt="GOK" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section class="py-4 py-lg-5 why-us-section">
        <div class="container">
            <h2 class="fancy-heading fancy-orange">Why Us?</h2>
            <div class="row mt-4 gx-lg-5">
                <div class="col-md-6 col-lg-4 my-2 my-lg-4">
                    <div class="site-card why-us-card h-100">
                        <img src="./img/FlippedClassrooms.svg" alt="Flipped classrooms" />
                        <h3>Flipped classrooms</h3>
                        <p>Study theory at home and focus on practice in live classes, where you’ll be guided by one of our expert tutors.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 my-2 my-lg-4">
                    <div class="site-card why-us-card h-100">
                        <img src="./img/LiveClasses.svg" alt="Live classes" />
                        <h3>Live classes</h3>
                        <p>Join our Zoom classes led by industry experts — up to 15 students per class, during stand-ups.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 my-2 my-lg-4">
                    <div class="site-card why-us-card h-100">
                        <img src="./img/Support.svg" alt="Support" />
                        <h3>Support</h3>
                        <p>Get the help you need to break into your new career. We provide our students with meaningful support and career guidance.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 my-2 my-lg-4">
                    <div class="site-card why-us-card h-100">
                        <img src="./img/SoftSkills.svg" alt="Soft Skills" />
                        <h3>Soft Skills</h3>
                        <p>Go beyond the technical stuff. Our approach emulates a real tech working environment, where our students work in groups, guided by a team lead.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 my-2 my-lg-4">
                    <div class="site-card why-us-card h-100">
                        <img src="./img/Employability.svg" alt="Employability" />
                        <h3>Employability</h3>
                        <p>Landing a job in tech can be tough — but we’ll provide you with the tools and knowledge necessary to nail your first interview.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 my-2 my-lg-4">
                    <div class="site-card why-us-card h-100">
                        <img src="./img/Community.svg" alt="Community" />
                        <h3>Community</h3>
                        <p>Get access to the Techsyiq Community that comprises our alumni, a vibrant, innovative development company, and a growing network of partners.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container home-courses mt-5">
            <h2 class="fancy-heading">Our Courses</h2>
            {/* {
                courseList.length > 0
                ? (
                <div className="row">
                    {courseList.map((course) => 
                    <HomeCourseList course={course} />
                    )}
                </div>
                )
                :
                <div>
                </div>
            } */}

            <div class="row">
                <div className="col-md-4 my-3">
                    <div className="site-card course-list-new-card">
                        <a href="" className="text-theme">
                            <h3 className="fancy-card-title text-theme lc-1">
                                Techie Mod
                            </h3>
                        </a>
                        <p className="lc-4">This is the basic module for a person who has little to no skills in the tech world. The main aim is to introduce anyone to the tech industry and prepare them thoroughly for a Software Engineering career in 12 weeks.</p>
                        <a href="#" class="course-card-btn">Learn More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-md-4 my-3">
                    <div className="site-card course-list-new-card">
                        <a href="" className="text-theme">
                            <h3 className="fancy-card-title text-theme lc-1">
                                Front-end Dev
                            </h3>
                        </a>
                        <p className="lc-4">This module is designed for a developer who has knowledge for the first module and wants to advance their front-end skills. This will ensure that the user is able to develop a front-end of a website and manage to interact with APIs in 16 weeks.</p>
                        <a href="#" class="course-card-btn">Learn More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
                <div className="col-md-4 my-3">
                    <div className="site-card course-list-new-card">
                        <a href="" className="text-theme">
                            <h3 className="fancy-card-title text-theme lc-1">
                                Back-end Dev
                            </h3>
                        </a>
                        <p className="lc-4">This module is designed for an advanced developer who aims at developing dynamic sites and integrating them with front-end frameworks through API development. The course period is 18 weeks</p>
                        <a href="#" class="course-card-btn">Learn More <i class="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            
        </div>
    </section>

    <section class="py-4 py-lg-5 cta-section">
        <div class="container">
            <div class="row align-items-center min-height">
                <div class="col-md-6">
                    <span class="vector-1"></span>
                    <span class="vector-2"></span>
                    <span class="vector-3"></span>
                </div>
                <div class="col-md-6">
                    <h2>Not sure where you fit?</h2>
                    <p>Talk to our educational advisors who will help you select the right course, prepare for admission, or just advise you on your next career goal.</p>
                    <a href="/book-call" className="btn btn-white">Book a Call</a>
                </div>
            </div>
        </div>
      </section>

      <section class="testimonials py-4 py-lg-5 mt-5">
        <Testimonials />
      </section>

      <Stats />
      <Newsletters />

    </>
  );
}

export default Home;
