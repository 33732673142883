import { useEffect, useState } from 'react';
import OtherArticles from '../Components/OtherArticles';
import ArticleFAQs from '../Components/ArticleFAQs';
import CallToAction from '../Components/CallToAction';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import Author from './Author';
import Newsletters from '../Components/Newsletters';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blog';


const Article = () => {
  const [article, setArticle] = useState(null)

  const params = useParams();
  const blogArticle  = () => { fetch(`${API_URL}/${params.id}`) 
    .then(response => {
      return response.json() 
    }) 
    .then(data => {
      setArticle(data)
      console.log(data)
    })}


  useEffect(() => {
    setTimeout(() => {
      blogArticle();
    }, 50);
  }, []);

  return (
    <>
    <section className="py-4 py-lg-5">
        <div className="container">
            <ul class="blog-breadcrumbs">
              <li><Link to="/blog" className='text-dark'>Blog</Link></li>
              <li><span class="text-pink">Success Stories</span></li>
            </ul>
            <div class="text-lg-center">
              <h1>
                Empowering Communities Through Code: The Impact of Techsyiq's Outreach Initiatives
              </h1>
            <p className="small text-muted mb-1 fw-400">June 23, 2024  ·  9:05 AM</p>
            </div>
            <div class="col-lg-9 ms-lg-auto">
              <p>In the heart of Kenya, where the vibrant culture meets the burgeoning tech scene, Techsyiq stands as a beacon of empowerment, transforming lives through its innovative approach to coding education. With a steadfast commitment to nurturing talent and bridging the digital divide, Techsyiq's outreach initiatives are making waves, catalyzing change, and fostering a more inclusive tech ecosystem. Let's delve into the profound impact of Techsyiq's outreach programs on communities across Kenya.</p>
            </div>


            <div class="row mt-4">
              <div class="col-12 my-3 order-2 order-lg-0">
                <img src="./img/article.jpeg" className='single-article-img' width="100%" alt="Empowering" />
              </div>
              <div class="col-lg-3 my-2 order-1 order-lg-0">
                <ul class="article-toc">
                  <li class="active">Bridging the Digital Divide: Bringing Coding Education to All</li>
                  <li>Cultivating Talent: Nurturing the Next Generation of Tech Innovators</li>
                  <li>Empowering Women in Tech: Breaking Barriers, Building Futures</li>
                  <li>Fostering Entrepreneurship: Turning Ideas into Reality</li>
                  <li>Strengthening Communities: Driving Social Impact Through Tech</li>
                  <li>Scaling Impact: Building a Sustainable Future</li>
                </ul>
                
                <h5 class="mt-4">Share this article</h5>
                <ul class="article-shares">
                  <li><a href=""><img src="./img/instagram.svg"/></a></li>
                  <li><a href=""><img src="./img/x.svg"/></a></li>
                  <li><a href=""><img src="./img/facebook.svg"/></a></li>
                  <li><a href=""><img src="./img/linkedin.svg"/></a></li>
                </ul>
              </div>
              <div class="col-lg-9 my-2 order-3 order-lg-0">
                <div class="article-heading-group">
                  <h2>Bridging the Digital Divide: Bringing Coding Education to All</h2>
                  <p>In a world increasingly shaped by technology, access to quality coding education is no longer a luxury but a necessity. However, for many communities in Kenya, especially those in underserved areas, such opportunities have been elusive. Recognizing this disparity, Techsyiq has taken proactive steps to bridge the digital divide by bringing coding education to all.</p>
                  <p>Through strategic partnerships with local schools, community centers, and NGOs, Techsyiq conducts coding workshops and training sessions in remote and marginalized areas. By making coding education accessible and engaging, Techsyiq empowers individuals from diverse backgrounds to unlock their potential and pursue careers in tech, thereby leveling the playing field and creating pathways to prosperity.</p>
                </div>

                <div class="article-heading-group">
                  <h2>Cultivating Talent: Nurturing the Next Generation of Tech Innovators</h2>
                  <p>At the core of Techsyiq's mission is the belief in the transformative power of talent cultivation. Through its outreach initiatives, Techsyiq identifies and nurtures promising young minds, equipping them with the skills and resources needed to thrive in the digital age.</p>
                  <p>From coding bootcamps to mentorship programs, Techsyiq provides aspiring technologists with hands-on training, guidance, and support. By fostering a culture of innovation and entrepreneurship, Techsyiq empowers the next generation of tech innovators to create solutions to local and global challenges, driving economic growth and sustainable development.</p>
                </div>

                <div class="article-heading-group">
                  <h2>Empowering Women in Tech: Breaking Barriers, Building Futures</h2>
                  <p>In many parts of the world, including Kenya, women remain underrepresented in the tech industry, facing numerous barriers and biases. Techsyiq is committed to changing this narrative by empowering women and girls to pursue careers in tech and become leaders in the field.</p>
                  <p>Through targeted outreach programs, such as coding workshops for girls and scholarships for women in tech, Techsyiq is breaking down barriers and creating inclusive spaces where women can thrive and succeed. By championing diversity and inclusion, Techsyiq is not only transforming individual lives but also driving positive change within the tech industry and beyond.</p>
                </div>

                <div class="row mt-4 align-items-center">
                  <div class="col-3 col-md-2 col-lg-1 pe-3">
                      <img src="./img/author.png" width="100%" className='author-prof-img' alt="" />
                  </div>
                  <div class="col-9 col-md-10 col-lg-11">
                      <p class="text-muted small mb-0">Author</p>
                      <h6 class="mb-1 font-noto">Peter Kennedy</h6>
                  </div>
                </div>
              </div>
            </div>

            
            {/* <Author id={article.author} /> */}
        </div>
    </section>

    <OtherArticles />

    {/* <ArticleFAQs /> */}

    <Newsletters />

    <CallToAction />
    </>
  );
}

export default Article;
