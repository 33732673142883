import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <section className="py-4 py-lg-5 call-to-action bg-pink">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-8 my-2">
                    <h2 className="text-white">Make the right decision</h2>
                    <p>You’re clicks away from embarking on an exciting and life-changing path.</p>
                    <Link to="/apply-now" className='btn btn-white-pink'>Apply Now</Link>
                </div>
                <div className="col-md-6 col-lg-4 my-2 ps-lg-5">
                    <div className="course-cta">
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default CallToAction;
