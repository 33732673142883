import { useEffect, useState } from 'react';

import BlogList from './BlogList';
import { Link } from 'react-router-dom';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';


const OtherArticles = () => {
    const [blogs, setBlogs] = useState([])
  
  const blogArticles = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    setBlogs(data)
  }


  useEffect(() => {
    blogArticles();
  }, []);



  return (
    <>
    <section className="py-4 py-lg-5 bg-light">
        <div className="container">
          <h2 className='text-dark mb-4'>Recommended Articles</h2>
            {/* {
                blogs.length > 0
                ? (
                <div className="row">
                    {blogs.map((blog) => 
                      <div className='col-md-3 my-2 blog-list-page'>
                        <BlogList blog={blog} />
                      </div>
                    )}
                </div>
                )
                :
                <div>
                </div>
            } */}
            <div class="row">
              <div class="col-lg-4 my-2">
                <div class="other-article-card">
                  <div class="other-article-img">
                    <img src="./img/article-2.jpeg" />
                    <span class="oac-category">News</span>
                  </div>
                  <div class="other-article-content">
                    <h3 class="lc-2">Techsyiq Announces Scholarship Program to Empower Women in Tech Across Africa</h3>
                    <p class="lc-3">Breaking barriers and fostering diversity, Techsyiq introduces a groundbreaking scholarship program aimed at empowering women in Africa's tech industry. Explore how this initiative seeks to address gender disparity by providing access to quality education and mentorship. Join us in celebrating the trailblazing women poised to shape the future of technology on the African continent.</p>
                    <p class="mb-0"><small>June 25, 2024</small></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 my-2">
                <div class="other-article-card">
                  <div class="other-article-img">
                    <img src="./img/article-2.jpeg" />
                    <span class="oac-category">Success Stories</span>
                  </div>
                  <div class="other-article-content">
                    <h3 class="lc-2">Techsyiq Announces Scholarship Program to Empower Women in Tech Across Africa</h3>
                    <p class="lc-3">Breaking barriers and fostering diversity, Techsyiq introduces a groundbreaking scholarship program aimed at empowering women in Africa's tech industry. Explore how this initiative seeks to address gender disparity by providing access to quality education and mentorship. Join us in celebrating the trailblazing women poised to shape the future of technology on the African continent.</p>
                    <p class="mb-0"><small>June 25, 2024</small></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 my-2">
                <div class="other-article-card">
                  <div class="other-article-img">
                    <img src="./img/article-2.jpeg" />
                    <span class="oac-category">Technical</span>
                  </div>
                  <div class="other-article-content">
                    <h3 class="lc-2">Techsyiq Announces Scholarship Program to Empower Women in Tech Across Africa</h3>
                    <p class="lc-3">Breaking barriers and fostering diversity, Techsyiq introduces a groundbreaking scholarship program aimed at empowering women in Africa's tech industry. Explore how this initiative seeks to address gender disparity by providing access to quality education and mentorship. Join us in celebrating the trailblazing women poised to shape the future of technology on the African continent.</p>
                    <p class="mb-0"><small>June 25, 2024</small></p>
                  </div>
                </div>
              </div>
            </div>

        {/* <div class="mt-4 text-center">
          <Link to="/blog" className="btn btn-outline-main">Go to Blog</Link>
        </div> */}
        </div>
    </section>
    </>
  );
}

export default OtherArticles;
