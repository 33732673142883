
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Blog from './Pages/Blog'
import Navbar from './Components/Navbar';
import TechieMod from './Pages/TechieMod';

import './App.css';
import Footer from './Components/Footer';
import Contact from './Pages/Contact';
import Apply from './Pages/Apply';
import Article from './Pages/Article';
import ArticleOld from './Pages/ArticleOld';
import FrontEndDev from './Pages/FrontEndDev';
import BackEndDev from './Pages/BackEndDev';
import Course from './Pages/Course';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import BookCall from './Pages/BookCall';
import CodeBuds from './Pages/CodeBuds';
// import Author from './Pages/Author';

const App = () => {
  return (
    <div className="App">
    <>
      <Navbar />
      <Routes>
        <Route path="/" element ={<Home />} />
        <Route path="/about" element ={<About />} />
        <Route path="/blog" element ={<Blog />} />
        <Route path="/techie-mod" element ={<TechieMod />} />
        <Route path="/front-end-dev" element ={<FrontEndDev />} />
        <Route path="/back-end-dev" element ={<BackEndDev />} />
        <Route path="/blog/:id" element ={<ArticleOld />} />
        <Route path="/single-article" element ={<Article />} />
        <Route path="/course/:id" element ={<Course />} />
        <Route path="/contact-us" element ={<Contact />} />
        <Route path="/book-call" element ={<BookCall />} />
        <Route path="/apply-now" element ={<Apply />} />
        <Route path="/terms-and-conditions" element ={<TermsAndConditions />} />
        <Route path="/privacy-policy" element ={<PrivacyPolicy />} />
        <Route path="/code-buds" element ={<CodeBuds />} />
      </Routes>

      <Footer />
      </>
      

      

      

      

    </div>
  );
}

export default App;
