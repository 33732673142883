import { useEffect, useState } from 'react';

import BlogList from './BlogList';
import { Link } from 'react-router-dom';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';


const WeekendPrograms = () => {
    const [blogs, setBlogs] = useState([])
  
  const blogArticles = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    setBlogs(data)
  }


  useEffect(() => {
    blogArticles();
  }, []);



  return (
    <>
    <section className="py-4 py-lg-5 bg-light">
        <div className="container">
          <h2 className='text-dark mb-4'>Weekend Programs</h2>
            {/* {
                blogs.length > 0
                ? (
                <div className="row">
                    {blogs.map((blog) => 
                      <div className='col-md-3 my-2 blog-list-page'>
                        <BlogList blog={blog} />
                      </div>
                    )}
                </div>
                )
                :
                <div>
                </div>
            } */}
            <div class="row">
              <div class="col-lg-4 my-2">
                <div class="program-card">
                  <div class="program-card-img">
                    <img src="./img/article-2.jpeg" />
                  </div>
                  <div class="program-content">
                    <h3 class="lc-2">Python Programming Basics Workshop</h3>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-blue">Sat, July 6, 2024</span>
                      <span class="custom-badge badge-blue">9:00am - 2:00pm</span>
                      <span class="custom-badge badge-blue">Ages 6 - 8</span>
                    </span>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-grey">#coding</span>  
                      <span class="custom-badge badge-grey">#python</span>  
                      <span class="custom-badge badge-grey">#basics</span>  
                    </span>
                    <p class="lc-3">Join us for a fun and interactive workshop where kids aged 6-8 will learn the fundamentals of Python programming. Through games and activities, they'll grasp basic coding concepts, logic, and problem-solving skills in a supportive environment. No prior experience necessary—just bring your curiosity and enthusiasm to learn!</p>
                    <div class="d-flex align-items-center">
                      <a href="" class="btn btn-orange btn-sm">Enroll</a>
                      <span class="text-orange d-inline-block ms-4 fw-bold">KSh 1,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 my-2">
                <div class="program-card">
                  <div class="program-card-img">
                    <img src="./img/article-2.jpeg" />
                  </div>
                  <div class="program-content">
                    <h3 class="lc-2">Python Programming Basics Workshop</h3>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-blue">Sat, July 6, 2024</span>
                      <span class="custom-badge badge-blue">9:00am - 2:00pm</span>
                      <span class="custom-badge badge-blue">Ages 6 - 8</span>
                    </span>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-grey">#coding</span>  
                      <span class="custom-badge badge-grey">#python</span>  
                      <span class="custom-badge badge-grey">#basics</span>  
                    </span>
                    <p class="lc-3">Join us for a fun and interactive workshop where kids aged 6-8 will learn the fundamentals of Python programming. Through games and activities, they'll grasp basic coding concepts, logic, and problem-solving skills in a supportive environment. No prior experience necessary—just bring your curiosity and enthusiasm to learn!</p>
                    <div class="d-flex align-items-center">
                      <a href="" class="btn btn-orange btn-sm">Enroll</a>
                      <span class="text-orange d-inline-block ms-4 fw-bold">KSh 1,000</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 my-2">
                <div class="program-card">
                  <div class="program-card-img">
                    <img src="./img/article-2.jpeg" />
                  </div>
                  <div class="program-content">
                    <h3 class="lc-2">Python Programming Basics Workshop</h3>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-blue">Sat, July 6, 2024</span>
                      <span class="custom-badge badge-blue">9:00am - 2:00pm</span>
                      <span class="custom-badge badge-blue">Ages 6 - 8</span>
                    </span>
                    <span class="inline-tabs">
                      <span class="custom-badge badge-grey">#coding</span>  
                      <span class="custom-badge badge-grey">#python</span>  
                      <span class="custom-badge badge-grey">#basics</span>  
                    </span>
                    <p class="lc-3">Join us for a fun and interactive workshop where kids aged 6-8 will learn the fundamentals of Python programming. Through games and activities, they'll grasp basic coding concepts, logic, and problem-solving skills in a supportive environment. No prior experience necessary—just bring your curiosity and enthusiasm to learn!</p>
                    <div class="d-flex align-items-center">
                      <a href="" class="btn btn-orange btn-sm">Enroll</a>
                      <span class="text-orange d-inline-block ms-4 fw-bold">KSh 1,000</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        {/* <div class="mt-4 text-center">
          <Link to="/blog" className="btn btn-outline-main">Go to Blog</Link>
        </div> */}
        </div>
    </section>
    </>
  );
}

export default WeekendPrograms;
