import Moment from 'react-moment';

const ArticleFAQs = () => {
    return (
        <section className="py-4 py-lg-5">
            <div className="container py-4">
                <h2>Frequently Asked Questions (FAQs)</h2>
                <div className="accordion mt-4" id="accordionFAQs">
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsOne" aria-expanded="true" aria-controls="collapseFAQsOne">
                            <span>
                                <span className="site-accordion-title">When is your intake?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsTwo" aria-expanded="true" aria-controls="collapseFAQsTwo">
                            <span>
                                <span className="site-accordion-title">Do you offer certificates?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsThree" aria-expanded="true" aria-controls="collapseFAQsThree">
                            <span>
                                <span className="site-accordion-title">Which age is best for my child to join at Techsyiq?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsFour" aria-expanded="true" aria-controls="collapseFAQsFour">
                            <span>
                                <span className="site-accordion-title">Do you offer personal tutoring?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
  export default ArticleFAQs;
  