import { useEffect, useState } from 'react';
import ArticleFAQs from '../Components/ArticleFAQs';
import CallToAction from '../Components/CallToAction';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import Newsletters from '../Components/Newsletters';
import WeekendPrograms from '../Components/WeekendPrograms';
import HolidayPrograms from '../Components/HolidayPrograms';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blog';


const CodeBuds = () => {
  const [article, setArticle] = useState(null)

  const params = useParams();
  const blogArticle  = () => { fetch(`${API_URL}/${params.id}`) 
    .then(response => {
      return response.json() 
    }) 
    .then(data => {
      setArticle(data)
      console.log(data)
    })}


  useEffect(() => {
    setTimeout(() => {
      blogArticle();
    }, 50);
  }, []);

  return (
    <>
    <section className='py-4 py-lg-5 code-buds-hero-section'>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h1>Codebuds: <span class="text-blue">Training Tech Leaders</span></h1>
            <p class="lead">Join Techsyiq's Codebuds Weekend and Holiday technology trainings for kids between 6 & 17 years old.</p>
            <a href="" class="btn btn-orange mt-3">Register Now</a>
          </div>
          <div class="col-lg-6 position-relative">
            <span class="vector-1"></span>
            <span class="vector-2"></span>
            <span class="vector-3"></span>
          </div>
        </div>
      </div>
    </section>
    <section className="py-4 py-lg-5 bg-white">
        <div className="container position-relative">
            <img class="fancy-icon" src="./img/fancy-icon.svg" alt=""/>
            <div class="col-lg-6 mx-auto text-center pt-lg-5">
              <h5 class="text-orange">IN SUMMARY</h5>
              <p>Our tech bootcamp is designed to inspire and educate the next generation of innovators. We offer topic-based weekend and holiday events, tailored to different age groups, ensuring that every child can find a program that suits their interests and skill levels.</p>
            </div>

            <div class="col-lg-9 mx-lg-auto mt-5">
              <div class="row">
                <div class="col-6 col-lg-4 mt-3 mb-2">
                  <div class="code-buds-icon-card">
                    <div class="cbic-icon pink">
                      <img src="./img/game-dev.svg" alt="" />
                    </div>
                    <p>Game Development</p>
                  </div>
                </div>
                <div class="col-6 col-lg-4 mt-3 mb-2">
                  <div class="code-buds-icon-card">
                    <div class="cbic-icon blue">
                      <img src="./img/web-design.svg" alt="" />
                    </div>
                    <p>Web Development</p>
                  </div>
                </div>
                <div class="col-6 col-lg-4 mt-3 mb-2">
                  <div class="code-buds-icon-card">
                    <div class="cbic-icon green">
                      <img src="./img/mobile-dev.svg" alt="" />
                    </div>
                    <p>Mobile Development</p>
                  </div>
                </div>
                <div class="col-6 col-lg-4 mt-3 mb-2">
                  <div class="code-buds-icon-card">
                    <div class="cbic-icon orange">
                      <img src="./img/robotics.svg" alt="" />
                    </div>
                    <p>Robotics</p>
                  </div>
                </div>
                <div class="col-6 col-lg-4 mt-3 mb-2">
                  <div class="code-buds-icon-card">
                    <div class="cbic-icon green">
                      <img src="./img/cyber-security.svg" alt="" />
                    </div>
                    <p>Cybersecurity Fundamentals</p>
                  </div>
                </div>
                <div class="col-6 col-lg-4 mt-3 mb-2">
                  <div class="code-buds-icon-card">
                    <div class="cbic-icon blue">
                      <img src="./img/design.svg" alt="" />
                    </div>
                    <p>Design</p>
                  </div>
                </div>
                
              </div>
            </div>
            
            {/* <Author id={article.author} /> */}
        </div>
    </section>

    <WeekendPrograms />

    <HolidayPrograms />

    <section class="py-4 py-lg-5">
      <div class="container">
        <div class="my-lg-4 text-center">
          <h2>What Our Codebuds Say</h2>

          <div class="code-bud-testimonial-card">
            <div class="cod-bud-img">
              <img src="./img/codbud.jpeg" alt=""/>
            </div>
            <h6>Mia, 10</h6>
            <p class="code-bud-stars">
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill"></i>
            </p>
            <p>I'm a current student at the bootcamp, and I really enjoy the holiday and weekend classes. I’ve learned so much about coding, and now I can even create my own animations and apps. It's the best experience ever!</p>
          </div>
        </div>
      </div>
    </section>

    <ArticleFAQs />

    <Newsletters />

    <CallToAction />
    </>
  );
}

export default CodeBuds;
