const PrivacyPolicy = () => {
  return (
    <>
      <section class="py-4 py-lg-5">
        <div class="container">
            <h1>Privacy Policy</h1>
            <p>Privacy Policy</p>
        </div>
      </section>

    </>
  );
}

export default PrivacyPolicy;
