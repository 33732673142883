import { useEffect, useState } from 'react';

import Newsletters from "../Components/Newsletters";

import BlogList from '../Components/BlogList';
import CallToAction from '../Components/CallToAction';
import BlogTabs from '../Components/BlogTabs';
import BlogTabsContent from '../Components/BlogTabContent';

const API_URL = 'https://backend-api.techsyiq.com/blog/fetch_blogs';


const Blog = () => {
    const [blogs, setBlogs] = useState([])
  
  const blogArticles = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    
    console.log(data)
    setBlogs(data)
  }


  useEffect(() => {
    // blogArticles();
  }, []);



  return (
    <>

        <section className="py-4 py-lg-5">
            <div className="container">
                <h1 className="text-blue">Techsyiq Blog</h1>
                <p>Read about our students, their success stories, and experience at Techsyiq.</p>

                <ul className="nav nav-pills mt-4 mb-5 custom-tab-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All Articles</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">News</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">Success stories</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">Guides</button>
                    </li>
                    <BlogTabs />
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-all" role="tabpanel">
                        {/* {
                            blogs.length > 0
                            ? (
                            <div>
                                {blogs.map((blog) => 
                                    <BlogList blog={blog} />
                                )}
                            </div>
                            )
                            :
                            <div>
                            </div>
                        } */}
                        <div className="article-list">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="blog-img-box">
                                        <img src="./img/article.jpeg" width="100%" alt="blog" />
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <p className="small text-muted mb-1 fw-400">
                                    <span className="text-blue">Success Stories </span>
                                    <span className="mx-2">|</span>
                                    June 28, 2024</p>
                                    <h2>
                                        <a href="single-article" className='text-dark'>Empowering Communities Through Code: The Impact of Techsyiq's Outreach Initiatives</a>
                                    </h2>
                                    <p className="lc-3">Witness the transformative impact of Techsyiq's community outreach programs as we spotlight success stories from underserved regions. From rural villages to urban centers, see how access to tech education is catalyzing socio-economic growth and empowerment. Through initiatives like coding workshops and mentorship programs, Techsyiq is bridging the digital divide and fostering a more inclusive tech ecosystem.</p>
                                </div>
                            </div>
                        </div>
                        <div className="article-list">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="blog-img-box">
                                        <img src="./img/article-1.jpeg" width="100%" alt="blog" />
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <p className="small text-muted mb-1 fw-400">
                                    <span className="text-blue">News</span>
                                    <span className="mx-2">|</span>
                                    June 28, 2024</p>
                                    <h2>
                                        <a href="single-article" className='text-dark'>Techsyiq Announces Scholarship Program to Empower Women in Tech Across Africa</a>
                                    </h2>
                                    <p className="lc-3">Breaking barriers and fostering diversity, Techsyiq introduces a groundbreaking scholarship program aimed at empowering women in Africa's tech industry. Explore how this initiative seeks to address gender disparity by providing access to quality education and mentorship. Join us in celebrating the trailblazing women poised to shape the future of technology on the African continent.</p>
                                </div>
                            </div>
                        </div>
                        <div className="article-list">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="blog-img-box">
                                        <img src="./img/article-2.jpeg" width="100%" alt="blog" />
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <p className="small text-muted mb-1 fw-400">
                                    <span className="text-blue">Technical</span>
                                    <span className="mx-2">|</span>
                                    June 28, 2024</p>
                                    <h2>
                                        <a href="single-article" className='text-dark'>Demystifying Machine Learning: A Beginner's Journey with Techsyiq's AI Bootcamp</a>
                                    </h2>
                                    <p className="lc-3">Embark on a journey into the realm of machine learning with Techsyiq's comprehensive AI bootcamp. Discover the fundamentals of algorithms, neural networks, and more, as we unravel the mysteries behind this transformative technology. Whether you're a coding novice or seasoned developer, our hands-on approach ensures a seamless learning experience in one of tech's most exciting fields.</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="blog-pagination">
                        <span class="pagination-nav disabled">Previous</span>
                            <ul class="pagination-numbers">
                                <li className='active'>1</li>
                                <li>2</li>
                                <li>3</li>
                                <li className='ellipsis'>...</li>
                                <li>9</li>
                            </ul>
                        <span class="pagination-nav">Next</span>
                    </div>
                    <BlogTabsContent />
                </div>
            </div>
        </section>

        <Newsletters />

        {/* <section className="py-4 py-lg-5">
            <div className="container py-4">
                <div className="accordion" id="accordionFAQs">
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsOne" aria-expanded="true" aria-controls="collapseFAQsOne">
                            <span>
                                <span className="site-accordion-title">You dedicate so much time to HTML and CSS. Why?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsTwo" aria-expanded="true" aria-controls="collapseFAQsTwo">
                            <span>
                                <span className="site-accordion-title">Are your courses competitive?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsThree" aria-expanded="true" aria-controls="collapseFAQsThree">
                            <span>
                                <span className="site-accordion-title">Do you guarantee employment after the course</span>
                            </span>
                        </button>
                        <div id="collapseFAQsThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button site-accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFAQsFour" aria-expanded="true" aria-controls="collapseFAQsFour">
                            <span>
                                <span className="site-accordion-title">Why don’t you teach Angular?</span>
                            </span>
                        </button>
                        <div id="collapseFAQsFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQs">
                        <div className="accordion-body">
                            Eum quisquam sequi eligendi assumenda tempore placeat porro accusamus corrupti non labore! Beatae temporibus et quos eum qui saepe mollitia voluptas fuga.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <CallToAction />

    </>
  );
}

export default Blog;
